import React from "react";
import { Education } from "./Education";
import { Experience } from "./Experience";
import { Projects } from "./Projects";
import { Extras } from "./Extras";

const NavContent = ({ selectedNav }) => {
  switch (selectedNav) {
    case "Education":
      return <Education />;
    case "Experience":
      return <Experience />;
    case "Projects":
      return <Projects />;
    case "Extras":
      return <Extras />;
    default:
      return (
        <div className="no-content">
          <h2>No Content Found</h2>
        </div>
      );
  }
};

export { NavContent };
