import React from "react";
import { FaReact } from "react-icons/fa";
import { GiAchievement } from "react-icons/gi";
import { FaCode } from "react-icons/fa";

import ibmLogo from "../../../assets/ibm.svg";
import ibmMQ from "../../../assets/ibmmq.png";
import ibmBlockchain from "../../../assets/ibmblockchain.png";
import ibmBlockchain2 from "../../../assets/ibmblockchain2.png";
import ibmPython from "../../../assets/ibmpython.png";
import ibmDocker from "../../../assets/ibmdocker.png";
import catapultLogo from "../../../assets/catapult.jpeg";
import winnowLogo from "../../../assets/winnow.png"

const Experience = () => {
  return (
    <div className="nav-content width-content">
      <div className="experience">
      <div className="header-container">
          <img className="logo large" style={{marginTop: "40px"}} src={winnowLogo} alt="Winnow" />
      </div>
      <div className="section">
          <FaCode style={{ marginRight: "10px" }} size={30} />
          <span>Software Engineer (Polyglot), Go & Python</span>
      </div>
      <div className="content">
      <ul>
        <li>
          Developing micro-services running on physical devices written in Go
        </li>
        <li>
          Infrastructure as code using Terraform and Terragrunt
        </li>
        <li>
          Balena to manage our fleet of over 1,500 IOT devices
        </li>
        <li>
          Telemetry data and integration with Grafana
        </li>
        <li>
          Concurrency, authentication and performance of applications running on devices
        </li>
        <li>
          Unit, E2E and Integration testing
        </li>
        <li>
          Image manipulation, motion detection via input from physical camera
        </li>
        <li>
          Smart software behaviour based on readings from physical sensors such as camera and scale
        </li>
        <li>
          AWS, Kafka, messaging and pub-sub within our environments
        </li>
        <li>
          Containerised development with a Jenkins pipeline
        </li>
        <li>
          GRPC for communicating between services over Unix sockets
        </li>
        <li>
          Strong code reviews and collaboration with other teams
        </li>
      </ul>
      </div>
      <div className="header-container">
          <img className="logo" src={catapultLogo} alt="Catapult" />
      </div>
      <div className="section">
          <FaCode style={{ marginRight: "10px" }} size={30} />
          <span>Software Engineer, Go & C++</span>
        </div>
        <div className="content">
          <ul>
          <li>
            Products include MatchTracker and Hub
          </li>
          <li>
            AWS services such as RDS, Cloudfront, Cognito, S3, Lambda
          </li>
          <li>
            GraphQL API written in Go supporting large data sets
          </li>
          <li>
            Docker and Terraform for deployment and service management
          </li>
          <li>
            Authentication, License generation and User management
          </li>
          <li>
            Complex and batched queries, automated microservices
          </li>
          <li>
            Video streaming, image uploads and signed links
          </li>
          <li>
            Industry standard Encryption and Data privacy
          </li>
          <li>
            Support for multiple flavours of products
          </li>
          <li>
            In-house tools and local web based products 
          </li>
          <li>
            QT & QML for Desktop Applications using C++
          </li>
          </ul>
        </div>
        <div className="header-container">
          <img className="logo" style={{ height: 99, width: 190 }} src={ibmLogo} alt="IBM" />
        </div>
        <div className="section">
          <FaReact style={{ marginRight: "10px" }} size={30} />
          <span>Software Engineer, React & Java</span>
        </div>
        <div className="content">
          <ul>
            <li>
              Front-end development using React
            </li>
            <li>
              IBM Carbon design system
            </li>
            <li>
              Mock API service
            </li>
            <li>
              Interface with IBM MQ
            </li>
            <li>
              IBM Blockchain product using the Hyperledger Fabric network
            </li>
            <li>
              Testing using Jest and Enzyme
            </li>
            <li>
              Applications communicating with MQ using Java and Python
            </li>
            <li>
              Scripting using Perl and Bash
            </li>
          </ul>
        </div>
        <div className="section spaced">
          <GiAchievement style={{ marginRight: "10px" }} size={30} />
          <span>Certificates</span>
        </div>
        <div className="content">
          <div className="logo-container">
            <img className="logo large" src={ibmMQ} alt="IBM MQ" />
            <img
              className="logo large"
              src={ibmBlockchain2}
              alt="IBM Blockchain"
            />
            <img className="logo large" src={ibmDocker} alt="IBM Docker" />
            <img className="logo large" src={ibmPython} alt="IBM Python" />
            <img
              className="logo extra-large"
              src={ibmBlockchain}
              alt="IBM Blockchain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Experience };
