import React from "react";
import { Header } from "./components/header";
import { Nav } from "./components/nav";
import { MobileNav } from "./components/nav";
import { NavContent } from "./components/nav-content";
import "./app.scss";

const App = () => {
  const [selectedNav, setSelectedNav] = React.useState("Education");
  return (
    <div className="app-container">
      <Header />
      <main className="main-container">
        <Nav selectedNav={selectedNav} setSelectedNav={setSelectedNav} />
        <MobileNav selectedNav={selectedNav} setSelectedNav={setSelectedNav} />
        <NavContent selectedNav={selectedNav} />
      </main>
    </div>
  );
};

export default App;
