import React from "react";
import { GiBookshelf } from "react-icons/gi";
import { GiSandsOfTime } from "react-icons/gi";
import { FaLaptopCode, FaMedal } from "react-icons/fa";

const Nav = ({ selectedNav, setSelectedNav }) => {
  const handleMenuClick = (menu) => (e) => {
    e.stopPropagation();
    setSelectedNav(menu);
  };
  return (
    <>
      <ul className="nav">
        <div className="nav-item-container width-content">
          <li
            className="nav-item"
            id="Education"
            onClick={handleMenuClick("Education")}
          >
            <GiBookshelf size={25} />
            <span id="Education" style={{ marginLeft: "5px" }}>
              Education
            </span>
            <span
              id="selected-icon-1"
              className={
                selectedNav === "Education"
                  ? "selected-icon"
                  : "selected-icon hidden"
              }
            />
          </li>
          <li
            className="nav-item"
            id="Experience"
            onClick={handleMenuClick("Experience")}
          >
            <GiSandsOfTime id="Experience" size={25} />
            <span id="Experience" style={{ marginLeft: "5px" }}>
              Experience
            </span>
            <span
              id="selected-icon-2"
              className={
                selectedNav === "Experience"
                  ? "selected-icon"
                  : "selected-icon hidden"
              }
            />
          </li>
          <li
            className="nav-item"
            id="Projects"
            onClick={handleMenuClick("Projects")}
          >
            <FaLaptopCode id="Projects" size={25} />
            <span id="Projects" style={{ marginLeft: "5px" }}>
              Projects
            </span>
            <span
              id="selected-icon-3"
              className={
                selectedNav === "Projects"
                  ? "selected-icon"
                  : "selected-icon hidden"
              }
            />
          </li>
          <li
            className="nav-item"
            id="Extras"
            onClick={handleMenuClick("Extras")}
          >
            <FaMedal id="Extras" size={25} />
            <span id="Extras" style={{ marginLeft: "5px" }}>
              Extras
            </span>
            <span
              id="selected-icon-4"
              className={
                selectedNav === "Extras"
                  ? "selected-icon"
                  : "selected-icon hidden"
              }
            />
          </li>
        </div>
      </ul>
    </>
  );
};

export { Nav };
