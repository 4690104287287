import React from "react";

import { FiScissors } from "react-icons/fi";
import { FaRoute } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { FaGamepad } from "react-icons/fa";
import { GiCartwheel } from "react-icons/gi";
import { FaAirbnb } from "react-icons/fa";
import { GiGamepad } from "react-icons/gi";
import { IoIosContact } from "react-icons/io";
import { FaFileCode } from "react-icons/fa";
import { GiMicrophone } from "react-icons/gi";
import { IoMdCodeWorking } from "react-icons/io";
import { GiClick } from "react-icons/gi";

const Projects = () => {
  const cards = [
    {
      title: "TCP Gaming Network",
      front: "A TCP gaming network for players. Verified connections, file delivery and data transfer over a secure a connection. Large data querying, search and account profiles.",
      back: "Written entirely using Go, multi-threaded system and compressed data management. Data security, encryption and indexing across large data sets. Unique protocol and packet sniffing/tampering prevention.",
      icon: <FaGamepad style={{ marginRight: "5px" }} size={20} />
    },
    {
      title: "HairFare",
      front:
        "A platform for barbers, hairdressers, salons and more to rent out chairs in their shops. The hosts are able to set prices on the services they provide, upload photos of the shop and set a price for each day of the week for their rental. Professionals are able to request to rent out a chair. The host can then accept or decline the booking. Each listing has ratings and reviews. ",
      back:
        "The project was built using React, TypeScript, Google Maps and Amazon Web Services such as Amplify, Cognito, DynamoDB, GraphQL, AppSync, API Gateway, Lambda, SES, S3 and Cloudfront.",
      link: "https://www.hairfare.co.uk",
      icon: <FiScissors style={{ marginRight: "5px" }} size={20} />,
    },
    {
      title: "Pathfinding Simulator",
      front:
        "An application which simulates pathfinding algorithms such as the AStar algorithm. The user starts by selecting the size of the grid. The user selects a starting cell and a destination cell. The user can block out cells which will not be considered in the result. The user can select the algorithm to use and the simulator will simulate finding the path.",
      back:
        "Built using Java and Java Swing. This project was one of the first projects I pursued to help me learn programming and practice what I have learned.",
      link: "https://github.com/SalsGithub/Pathfinding-Simulation",
      icon: <FaRoute style={{ marginRight: "5px" }} size={20} />,
    },
    {
      title: "MyBuddy NHS App",
      front:
        "An app made in second year of studies for the NHS. The clinicans will have a buddy in the office to check up on them whilst visiting a patient. The clinician can send emergency pre-set messages and send live location. In the case of an emergency, the clinicians team will receive an emergency notification.",
      back:
        "Built using React and React Native. We used Google Firebase and Microsoft office's API to access outlook's calendar. This was part of second year software engineering group project which scored very high marks.",
      icon: <FaHandshake style={{ marginRight: "5px" }} size={20} />,
    },
    {
      title: "Reverse Engineering Project",
      front:
        "Reverse engineered a famous game client to connect to a custom code base. The custom code base emulated the original game content and mechanics, with custom content as well. Supports regional player and NPC updating, object updating, floor item updating, multiplayer support.",
      back:
        "This project was solely for learning purposes. This project was built entirely in Java, Netty was used for connection handling and built in Java bootstrap to launch the game at a specific port. The game data was dumped from the real game using a script. Included RSA encryption from server to client.",
      icon: <GiCartwheel style={{ marginRight: "5px" }} size={20} />,
    },

    {
      title: "Airbnb Project",
      front:
        "Over 50,000 property data from Airbnb was provided. We made a property booking system which allowed users to view properties in boroughs of London. Users can set price range and filter the properties. We had statistics such as dearest and cheapest boroughs. The user had options to enter phrases such as 'pets welcome' to also filter their search.",
      back:
        "Part of first year group project which used Java and JavaFX. We scored a grade of 100% as code was clean, SOLID principles were used and best practices.",
      link: "https://github.com/SalsGithub/AirBnb",
      icon: <FaAirbnb style={{ marginRight: "5px" }} size={20} />,
    },
    {
      title: "Game Renting",
      front:
        "A web application as part of second year studies where users were able to view and rent games as well as return the game. The application would display games available to rent and not display ones which are currently rented out.",
      back:
        "HTML, CSS and PHP were used throughout the project. We also used a SQL database to store the data and queried the data for searching, results and editing these.",
      icon: <GiGamepad style={{ marginRight: "5px" }} size={20} />,
    },
    {
      title: "Online CV/Portfolio",
      front:
        "A web application written in React to create an online CV/Portfolio, responsive to all screen sizes. An example of this is this website!",
      back:
        "React was used to developed this website. The styling (CSS) is custom and designed by myself. The latest update was on the 6th of May 2021.",
      icon: <IoIosContact style={{ marginRight: "5px" }} size={20} />,
    },

    {
      title: "Code Diary",
      front:
        "A very lightweight application created for developers to write notes and save them. The notes can be categorised and a very simple user interface was designed to manage the notes. Notes can be vied by date, author and category.",
      back:
        "Written in Java and JavaFX as a small project. Notes are saved on the users disk and can be viewed and edited.",
      icon: <FaFileCode style={{ marginRight: "5px" }} size={20} />,
      link: "https://github.com/SalsGithub/Update-Logger",
    },
    {
      title: "The Voice",
      front:
        "A database to store competitors auditioning for a singing competition. Competitors must have a valid ticket and can be a group or a solo performer. This relational database took care of dates, the managers, groups and the people verified to be on the show.",
      back:
        "A relational database made in first year of studies to learn the fundamentals of database systems. SQL queries were written to view data with complex queries meeting the needs of the project.",
      icon: <GiMicrophone style={{ marginRight: "5px" }} size={20} />,
    },
    {
      title: "Miscellaneous",
      front:
        "A collection of programs ranging from sorting algorithms, pathfinders, puzzle solvers, string builders, computations, famous number puzzles and more. Includes solutions to online puzzles such as codingbat, other puzzles online as well as utility programs for images, files, folders, re-usable code, data structures and more.",
      back:
        "Many languages were used for practice and improving knowledge of each language being used. Some of the same code was written in different languages as challenges. Languages include C++, Java, Scala, Python, JavaScript.",
      icon: <IoMdCodeWorking style={{ marginRight: "5px" }} size={20} />,
    },
  ];

  const openLink = (link) => (e) => window.open(link, "_blank");

  const [flippedCards, setFlippedCards] = React.useState([]);

  const isFlipped = (cardTitle) => {
    return flippedCards.includes(cardTitle);
  };

  const flipCard = (cardTitle) => (e) => {
    //Prevent card flip back when button clicked
    if (e.target.id) {
      e.stopPropagation();
      return;
    }
    if (isFlipped(cardTitle)) {
      setFlippedCards((flippedCards) =>
        flippedCards.filter((card) => card !== cardTitle)
      );
    } else {
      setFlippedCards((previousState) => previousState.concat(cardTitle));
    }
  };

  return (
    <div className="nav-content width-content">
      <div className="projects">
        <div className="header">
          <GiClick style={{ marginRight: "5px" }} size={25} />
          Click/tap on the cards below to reveal more.
        </div>
        <div className="flip-card-container">
          {cards.map((card, i) => {
            return (
              <div onClick={flipCard(card.title)} key={i} className="flip-card">
                <div
                  className={
                    isFlipped(card.title)
                      ? "flip-card-inner flipped"
                      : "flip-card-inner"
                  }
                >
                  <div className="flip-card-front">
                    <div className="title">
                      {card.icon && <>{card.icon}</>}
                      {card.title}
                    </div>
                    <div className="body">{card.front}</div>
                  </div>
                  <div className="flip-card-back">
                    <div className="body">{card.back}</div>
                    {card.link && (
                      <div
                        onClick={openLink(card.link)}
                        id="button"
                        className="button"
                      >
                        View
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { Projects };
