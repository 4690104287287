import React from "react";

const useOnClickOutside = (callback, callOnEsc) => {
  if (callOnEsc === void 0) {
    callOnEsc = true;
  }
  const ref = React.useRef(null);
  React.useEffect(
    function () {
      const handleClick = (e) => {
        // If we we haven't set the node yet, or a click occurs within the target, return.
        if (ref.current == null || ref.current.contains(e.target)) {
          return;
        }
        // If the click is a left click and outside the target,
        // run the callback.
        if (e.buttons === 1) {
          callback();
        }
      };
      const handleEsc = (e) => {
        if (ref.current != null && callOnEsc) {
          if (e.keyCode === 27) {
            callback();
          }
        }
      };
      const handleTouch = (e) => {
        // If we we haven't set the node yet, or a click occurs within the target, return.
        if (ref.current == null || ref.current.contains(e.target)) {
          return;
        } else {
          callback();
        }
      };
      // Add event listener.
      document.addEventListener("mousedown", handleClick);
      document.addEventListener("touchstart", handleTouch);
      document.addEventListener("keydown", handleEsc);
      // Tear down event listener on cleanup.
      return function () {
        document.removeEventListener("mousedown", handleClick);
        document.removeEventListener("touchstart", handleTouch);
        document.removeEventListener("keydown", handleEsc);
      };
    },
    [callback, callOnEsc]
  );
  return { ref: ref };
};

export { useOnClickOutside };
