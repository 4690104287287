import React from "react";

const SocialLink = (props) => {
  return (
    <div className={"social-link " + props.extraClassName}>
      <a href={props.link} rel="noopener noreferrer" target="_blank">
        <img src={props.src} alt={props.alt} />
      </a>
    </div>
  );
};

export { SocialLink };
