import React from "react";

import headerBackgroundImage from "../../../assets/header-background.jpeg";
import headerImage from "../../../assets/me.png";
import linkedIn from "../../../assets/linkedin.svg";
import github from "../../../assets/github.svg";
import { SocialLink } from "../../social/components/SocialLink";

const Header = () => {
  const headerTitle = "Software Engineer";
  return (
    <header
      className="header-content"
      style={{ backgroundImage: "url(" + headerBackgroundImage + ")" }}
    >
      <h1 style={{ color: "#000" }}>{headerTitle}</h1>
      <img
        className="header-image"
        src={headerImage}
        alt="Header placeholder"
      />
      <div className="social-link-container">
        <SocialLink
          extraClassName="linkedin"
          src={linkedIn}
          alt=""
          link="https://www.linkedin.com/in/salchousein/"
        />
        <SocialLink src={github} alt="" link="https://github.com/salsgithub" />
      </div>
    </header>
  );
};

export { Header };
