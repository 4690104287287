import React from "react";

import { FaRunning } from "react-icons/fa";
import { IoIosContacts } from "react-icons/io";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";

const Extras = () => {
  return (
    <div className="nav-content width-content">
      <div className="extras">
        <div className="header">
          <FaRunning style={{ marginRight: "5px" }} size={25} />
          <span>Sports</span>
        </div>
        <div className="content">
          Since primary school, I have always represented my school in football
          as a centre mid-fielder. I have also competed at a high
          level with teams in the local area as well trying out for higher end
          teams which my football manager recommended me to. In my secondary school,
          I took part in athletics competing in the 800m and 1500m races. 
          I also completed four London Virgin Mini Marathons as well as the Newham London run.
          In one race I placed in the top eight and won a ticket to the 2012 Olympics 
          where I admired Usain Bolt in the 100m mens heats. I also took part in district cross
          country races ranging from 5,000 metres to 10,000 metres.
        </div>
        <div className="header">
          <IoIosContacts style={{ marginRight: "5px" }} size={25} />
          <span>Tutoring</span>
        </div>
        <div className="content">
          During my free time whilst studying at University, I also tutored
          younger students who were studying Computer Science in various areas
          ranging from programming, mathematics and module specific content. I
          had a good reputation on tutoring websites where I was receiving work
          from and keeping in touch with my students.
        </div>
        <div className="header">
          <GiPerspectiveDiceSixFacesRandom
            style={{ marginRight: "5px" }}
            size={25}
          />
          <span>Hobbies</span>
        </div>
        <div className="content">
          Attending hackathons was a big hobby of mine to improve myself. I also
          like to take part in various programming challenges and puzzles. I was
          also able to solve the rubiks cube in under a minute and perform a few
          magic tricks. As a person who got into programming through games, I
          spend some of my time playing online games which influenced me to
          pursue a career in development. Although gaming can be time consuming,
          I find it is a good outlet to release stress and increase creativity
          and innovation by playing strategic and skill based games. Admiring the
          hard work that has been put into developing amazing games can be used as motivation
          and push you to work harder.
        </div>
      </div>
    </div>
  );
};

export { Extras };
