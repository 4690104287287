import React from "react";
import { Menu } from "react-feather";
import { GiBookshelf } from "react-icons/gi";
import { GiSandsOfTime } from "react-icons/gi";
import { FaLaptopCode, FaMedal } from "react-icons/fa";

const getNavMenuIcon = (navMenu) => {
  switch (navMenu) {
    case "Education":
      return <GiBookshelf size={21} />;
    case "Experience":
      return <GiSandsOfTime size={21} />;
    case "Projects":
      return <FaLaptopCode size={21} />;
    case "Extras":
      return <FaMedal size={21} />;
    default:
      return null;
  }
};

const MenuOptions = React.forwardRef(
  ({ toggleMenu, selectedNav, setSelectedNav }, ref) => {
    const navMenuOptions = ["Education", "Experience", "Projects", "Extras"];

    const handleMenuClick = (menuOption) => {
      setSelectedNav(menuOption);
      toggleMenu();
    };

    const getNavMenuOptions = () => {
      return navMenuOptions
        .filter((menu) => menu !== selectedNav)
        .map((menu, key) => {
          return (
            <div
              className="nav-item"
              key={key}
              onClick={() => handleMenuClick(menu)}
            >
              {getNavMenuIcon(menu)}
              <span>{menu}</span>
            </div>
          );
        });
    };
    return (
      <div ref={ref} className="nav-options">
        {getNavMenuOptions()}
      </div>
    );
  }
);

const MobileNav = ({ selectedNav, setSelectedNav }) => {
  const [navMenuOpen, setNavMenuOpen] = React.useState(false);
  const wrapperRef = React.createRef();

  const toggleMenu = React.useCallback(() => {
    navMenuOpen ? setNavMenuOpen(false) : setNavMenuOpen(true);
  }, [navMenuOpen]);

  React.useEffect(() => {
    const handleClickOutside = (e, ref) => {
      if (ref.current) {
        if (!ref.current.contains(e.target)) {
          toggleMenu();
        }
      }
    };

    const clickOutsideListener = (e) => handleClickOutside(e, wrapperRef);

    document.addEventListener("click", clickOutsideListener);

    return () => {
      document.removeEventListener("click", clickOutsideListener);
    };
  }, [wrapperRef, toggleMenu]);

  return (
    <div className="mobile-nav">
      <div className="current-menu">
        <div style={{ marginRight: "5px" }}>{getNavMenuIcon(selectedNav)}</div>
        {selectedNav}
      </div>
      <Menu className="icon" onClick={toggleMenu} />
      {navMenuOpen && (
        <MenuOptions
          ref={wrapperRef}
          toggleMenu={toggleMenu}
          selectedNav={selectedNav}
          setSelectedNav={setSelectedNav}
        />
      )}
    </div>
  );
};

export { MobileNav };
