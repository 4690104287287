import React from "react";
import { FaTimes } from "react-icons/fa";
import { useOnClickOutside } from "../../util/components/useOnClickOutside";

const useLockBody = (lockBody) => {
  React.useEffect(() => {
    document.body.style.overflow = lockBody ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [lockBody]);
};

const CustomModal = ({ open, close, children, title }) => {
  useLockBody(open);
  const bind = useOnClickOutside(close);

  if (!open) {
    return null;
  }

  return (
    <>
      <div className="modal-overlay" />
      <div {...bind} className="modal">
        <FaTimes className="cross-icon" onClick={close} />
        <h2>{title}</h2>
        {children}
      </div>
    </>
  );
};

export { CustomModal };
