import React from "react";
import { CustomModal } from "../../modal";

import { GiTimeTrap } from "react-icons/gi";
import { FaGraduationCap } from "react-icons/fa";

import kclBanner from "../../../assets/kcl-banner.jpg";

let modalTitle;
let modalBody;

const Education = () => {
  const [selectedTab, setSelectedTab] = React.useState("Final Year");
  const [modalOpen, setModalOpen] = React.useState(false);

  const isSelected = (tab) => {
    return selectedTab === tab;
  };

  const renderContent = (tab) => {
    switch (tab) {
      case "Final Year":
        return renderFinalYear();
      case "Third Year":
        return renderThirdYear();
      case "Second Year":
        return renderSecondYear();
      case "First Year":
        return renderFirstYear();
      default:
        return null;
    }
  };

  const closeModal = () => setModalOpen(false);

  const setModalProps = (title, inner) => {
    modalTitle = title;
    modalBody = inner;
    setModalOpen(true);
  };

  const itemise = (items) => {
    return (<div className="item-container"><hr/>
      {items.map((item, key) => (
        <p key={key}>{item}</p>
      ))}
      </div> 
     );
  }

  const viewModal = () => {
    return (
      <CustomModal
        title={modalTitle}
        open={modalOpen}
        close={() => {
          setModalOpen(false);
        }}
      >
        <div className="Body">
          <div className="Inner">{modalBody}</div>
        </div>
        <div className="modal-button-container">
          <button className="button" onClick={closeModal}>
            Close
          </button>
        </div>
      </CustomModal>
    );
  };

  const renderFinalYear = () => {
    return (
      <>
        <div className="grade">
          <span>Grade - First Class Honours</span>
          <FaGraduationCap style={{ marginLeft: "10px" }} size={40} />
        </div>
        <table className="module-table">
          <thead>
            <tr>
              <th>Module</th>
              <th>Technology</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Artifical Intelligence Reasoning and Decision Making</td>
              <td>Python</td>
              <td>
                <button
                  onClick={() => {
                    const list = ['Probability', 'Game Theory', 'Bayesian Networks & Prior Sampling', 
                    'Sequential Decision Making', 'Matrix, Rotations & PCA', 'Clustering', 'Argumentation', 
                    'Flocks & Swarms', 'Ethics'];
                    setModalProps(
                      "Artifical Intelligence Reasoning and Decision Making",
                      <div className="view-module-container">
                        <p>Python was used in developing an artificially intelligent pacman. Pacman was able to win games by avoiding ghosts and used the Markov decision process to make decisions.</p>
                        Grade: 78%
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Cryptography</td>
              <td>Mathematics</td>
              <td>
                <button
                  onClick={() => {
                    const list = ['Number Theory', 'Ciphers', 'Attacks & Cryptosystems', 'Encryption, Hashing & Password Salting',
                                'Key-establishment Protocols', 'Authentication and Identification', 'Digital Signatures', 
                                'Information Security'];
                    setModalProps(
                      "Cryptography",
                      <div className="view-module-container">
                        <p>A rigorous module in understanding cryptography, taught by a top experienced professor at King's.</p>
                        Grade: 80%
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Human-Computer Interaction</td>
              <td>Design</td>
              <td>
                <button
                  onClick={() => {
                    const list = ['Design Thinking', 'User Needs & System Requirements', 'Usability & Cognition', 'Aesthetic & Appeal', 'User Experience', 'Augmented Reality'];
                    setModalProps(
                      "Human-Computer Interaction",
                      <div className="view-module-container">
                        <p>The study of user experience and design. Coursework involved designing web tools to help students manage their schedule and deadlines through lockdown.</p>
                        Grade: 70%
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Optimisation Methods</td>
              <td>Mathematics</td>
              <td>
                <button
                  onClick={() => {
                    const list = ['Shortest Path Problem', 'Geographical Networks', 'Network Flow Problems', 'Multi-commodity Flow Problems', 'Combinatorial Optimisation', 'Time Complexity', 'Travelling Salesman Problem', 'Linear Programming', 'Heuristic Search', 'Genetic Algorithms', 'Simulated Annealing'];
                    setModalProps(
                      "Optimisation Methods",
                      <div className="view-module-container">
                          <p>The study of efficient methods and algorithms to solve problems that can be applied in a variety of settings.</p>
                          Grade: 70%
                          {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Network and Information Security</td>
              <td>Kali Linux, Wireshark</td>
              <td>
                <button
                  onClick={() => {
                    const list = ['TCP, UDP, IP Architecture', 'Sniffing & Traffic Analysis', 'Hijacking & Poisoning', 'Firewalls', 'Intrusion Detection & Prevention', 'IPSec', 'IKE and SSL/TLS', 'Privacy & Anonymity', 'Wireless and IOT Security'];
                    setModalProps(
                      "Information Security",
                      <div className="view-module-container">
                        <p>Involved a practical coursework to demonstrate network security protocols. The coursework involved attacking a network, analysing the network and defending against attacks. Different types of attacks were carried out such as denial of service, spoofing and poisoning.</p>
                        Grade: 93%
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Distributed Ledgers and Crypto-currencies</td>
              <td>Blockchain</td>
              <td>
                <button
                  onClick={() => {
                    const list = ['Bitcoin Blockchain', 'Cryptography', 'Mining', 'Consensus Protocols', 'Proof of Work & Proof of Stake', 'Money & E-Money', 'ICOs and TGEs', 'DLT Platforms'];
                    setModalProps(
                      "Distributed Ledgers and Crypto-currencies",
                      <div className="view-module-container">
                        <p>A masters level module which involved exploring blockchain technology and understanding the protocols. The coursework involved using blockchain technology and smart contracts for a track and trace system for artwork.</p>
                        Grade: 70%
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Individual Project (Dissertation)</td>
              <td>Software Development and Report writing</td>
              <td>
                <button
                  onClick={() => {
                    const list = ['Vehicle Routing Problem', 'Travelling Salesman Problem', 'Path Finding', 'Algorithm Optimisation', 'Optimised Local Search', 'Decision Making', 'Dynamic State'];
                    setModalProps(
                      "Individual Project",
                      <div className="view-module-container">
                        <p>My disseration was based on the vehicle routing problem modelled as a dynamic problem
                           as opposed to the usual static approach. A software was built to simulate routing strategies that
                           can be used. Strategies involved using optimised local search and a map system. The user is able to
                           control the simulation and dynamically change the state to observe how the vehicles should change
                           their journeys.</p>
                        Grade: 73%
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderThirdYear = () => {
    return (
      <>
        <div className="grade">
          <GiTimeTrap style={{ marginRight: "10px" }} size={30} />
          <span>Industrial Placement Year - 13 Months</span>
        </div>
        <div className="content">
          <p>
            I was successful in landing a one year internship at IBM as a 
            Software Developer for my industry year. I had an invaluable
            experience in the department of MQ, situated in Hursley which is
            the largest development lab in Europe. I really enjoyed my year
            at IBM and even extended my placement for an extra month. I speak
            more about my time at IBM on the 'Experience' tab.
          </p>
        </div>
      </>
    );
  };

  const renderSecondYear = () => {
    return (
      <>
        <div className="grade">
          <span>
            Grade - First Class
          </span>
        </div>
        <table className="module-table">
          <thead>
            <tr>
              <th>Module</th>
              <th>Technology</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Practical Experiences of Programming</td>
              <td>C++, Scala</td>
              <td>
                <button
                  onClick={() => {
                    const list = ["Algorithms", "Data Structures", "Trees & Traversals", "Bit Manipulation", 
                                  "Puzzle Solvers (sudoku, string, number)", "Sorting Algorithms"];
                    setModalProps(
                      "Practical Experiences of Programming",
                      <div className="view-module-container">
                        <p>
                          This module consisted of ten practical courseworks,
                          five C++ and five Scala as well as a legislation
                          coursework. This module was very intense with lots of
                          tricky puzzles and algorithms to implement however was
                          my highest achieving grade of 92%.
                        </p>
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Introduction to Artificial Intelligence</td>
              <td>PDDL</td>
              <td>
                <button
                  onClick={() => {
                    const list = ["Data Analysis", "Data Manipulation", "Decision Trees", "Clustering", "Data Sampling", "Calculations"];
                    setModalProps(
                      "Introduction to Artifical Intelligence",
                      <div className="view-module-container">
                        <p>
                          This module consisted of a group coursework and a
                          final exam. We designed an AI system using PDDL which
                          would automate sorting books inside a library. The
                          coursework was very successful.
                        </p>
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Software Engineering Group Project</td>
              <td>JavaScript, React & React Native, Firebase</td>
              <td>
                <button
                  onClick={() => {
                    setModalProps(
                      "Software Engineering Group Project",
                      <div className="view-module-container">
                        <p>
                          We developed from scratch a mobile application which
                          was compatible with IOS and Android. Clinicians would
                          meet patients at their home and they wanted peace of
                          mind knowing someone was looking out for them in the
                          case of an emergency. We built this using React &
                          React Native and Firebase.
                        </p>
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Programming Language Design</td>
              <td>Haskell, Java</td>
              <td>
                <button
                  onClick={() => {
                    const list = ["Imperative Languages", "Hybrid Languages", "Functional Languages", "Grammar & Syntax", 
                                  "Parsing & Typing", "Trees & Mathematical Induction"]
                    setModalProps(
                      "Programming Language Design",
                      <div className="view-module-container">
                        <p>
                          This module taught us the development of programming
                          languages and how the internals are designed. This was
                          a fundamental module which I think was very critical
                          to further improve as a developer.
                        </p>
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Operating Systems and Concurrency</td>
              <td>Java</td>
              <td>
                <button
                  onClick={() => {
                    const list = ["Multi-threading", "Deadlock, Starvation", "Memory, Paging & Caching", "Operating System Fundamentals",
                                  "Reverse Engineering", "Security", ""]
                    setModalProps(
                      "Operating Systems and Concurrency",
                      <div className="view-module-container">
                        <p>
                          Multithreaded systems are evolving and increasing the
                          speed of tasks being executed. This module was crucial
                          to know how to write multithreaded systems and how to
                          make the best use of memory and synchronization.
                        </p>
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Foundations of Computing 2</td>
              <td>Mathematics</td>
              <td>
                <button
                  onClick={() => {
                    const list = ["P vs NP", "Pathfinding Algorithms", "Linear Programming", "Mathematical Induction", "Time Complexity", "Puzzle Solving"]
                    setModalProps(
                      "Foundations of Computing 2",
                      <div className="view-module-container">
                        <p>
                          A mathematics based module which taught us
                          fundamentals of speed, time complexity along with
                          algorithms and best approaches. Module included many
                          famous and hard puzzles.
                        </p>
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Internet Systems</td>
              <td>HTTP, HTML, XML, SOAP, REST</td>
              <td>
                <button
                  onClick={() => {
                    const list = ["HTTP Protocol", "HTML", "Database SQL", "REST & SOAP", "Encryption & Decryption", "Hashing"]
                    setModalProps(
                      "Internet Systems",
                      <div className="view-module-container">
                        <p>
                          This module taught the web protocols and how they are
                          used. The module included security on the web and how
                          to protect your service from vulnerabilities.
                        </p>
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderFirstYear = () => {
    return (
      <>
        <div className="grade">
          <span>
            Grade - First Class
          </span>
        </div>
        <table className="ModuleTable">
          <thead>
            <tr>
              <th>Module</th>
              <th>Technology</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Computer Systems</td>
              <td>Binary</td>
              <td>
                <button
                  onClick={() => {
                    const list = ["Memory", "Virtual Memory", "Binary & Arithmetic", "Arduino Programming", "Hardware Architecture"]
                    setModalProps(
                      "Computer Systems",
                      <div className="view-module-container">
                        <p>
                          This module focused on the internal architecture
                          of a computer and how tasks are carried out. Lab projects
                          involved developing programs using assembly for an Arduino.
                        </p>
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Database Systems</td>
              <td>SQL</td>
              <td>
                <button
                  onClick={() => {
                    setModalProps(
                      "Database Systems",
                      <div className="view-module-container">
                        <p>
                          This was a hands on module which taught us databases,
                          how they work and how to setup and use a database.
                          This focused entirely around SQL queries,
                          vulnerabilities and prepared statements.
                        </p>
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Data Structures</td>
              <td>Java</td>
              <td>
                <button
                  onClick={() => {
                    const list = ["Arrays", "Maps", "Trees", "Lists", "Stacks", "Algorithms & Operations"]
                    setModalProps(
                      "Data Structures",
                      <div className="view-module-container">
                        <p>
                          This module focused on internal data structures and
                          libraries supporting these as well as operations
                          within them.
                        </p>
                        {itemise(list)}
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Elementary Logic with Applications</td>
              <td>Truth tables</td>
              <td>
                <button
                  onClick={() => {
                    setModalProps(
                      "Elementary Logic with Applications",
                      <div className="view-module-container">
                        <p>
                          Principles of logic, decision making and logical
                          deduction. Truth tables and De Morgan's laws were
                          practiced.
                        </p>
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Foundations of Computing</td>
              <td>Mathematics</td>
              <td>
                <button
                  onClick={() => {
                    setModalProps(
                      "Foundations of Computing",
                      <div className="view-module-container">
                        <p>
                          A computational module in performing computations on
                          data, mathematical induction and algorithms.
                        </p>
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Introduction to Software Engineering</td>
              <td>Java</td>
              <td>
                <button
                  onClick={() => {
                    setModalProps(
                      "Introduction to Software Engineering",
                      <div className="view-module-container">
                        <p>
                          This module focused us to the software development
                          lifecycle. Versioning tools and software best
                          practices were used with a project using Java.
                        </p>
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
            <tr>
              <td>Programming Pratice and Applications</td>
              <td>Java, Python</td>
              <td>
                <button
                  onClick={() => {
                    setModalProps(
                      "Programming Pratice and Applications",
                      <div className="view-module-container">
                        <p>
                          This was a hands on module teaching us programming
                          using mainly Java and some Python. Many courseworks
                          were assigned to demonstrate our understanding as well
                          as a final group project. My group developed a room booking software
                          for a property which scored a grade of 100%.
                        </p>
                      </div>
                    );
                  }}
                  className="button full-width"
                >
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const setTab = (tab) => (e) => setSelectedTab(tab);

  return (
    <div className="nav-content width-content">
      <div className="education">
        <img src={kclBanner} className="logo" alt="King's College London" />
        <div style={{ marginBottom: "20px" }}>
          <div>Computer Science with an Industrial Placement Year 2017-2021</div>
        </div>
        <div>
          <ul className="education-nav">
            <li
              className={
                isSelected("Final Year") ? "nav-item selected" : "nav-item"
              }
              onClick={setTab("Final Year")}
            >
              Final Year
            </li>
            <li
              className={
                isSelected("Third Year") ? "nav-item selected" : "nav-item"
              }
              onClick={setTab("Third Year")}
            >
              Third Year
            </li>
            <li
              className={
                isSelected("Second Year") ? "nav-item selected" : "nav-item"
              }
              onClick={setTab("Second Year")}
            >
              Second Year
            </li>
            <li
              className={
                isSelected("First Year") ? "nav-item selected" : "nav-item"
              }
              onClick={setTab("First Year")}
            >
              First Year
            </li>
          </ul>
          {renderContent(selectedTab)}
          {modalOpen && viewModal()}
        </div>
      </div>
    </div>
  );
};

export { Education };
